import { useEffect } from "react";
import { useLoaderData, useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../redux/hooks";
import { updateSessionId, updateTableNumber } from "../redux/slices";
//import { Loading } from './Loading';
import { SessionCheckResponse } from "../types/player";
import ReactGA from "react-ga4";

export const ValidateLink: React.FC = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const sessionStatus = useLoaderData() as SessionCheckResponse;
    let sessionId = searchParams.get('s');
    let tableNumber = searchParams.get('t');
    const dispatch = useAppDispatch();

    useEffect(() => {        // TODO, if session id is null, then we need to redirect to the error page
        
        console.log("venue_code: " + sessionStatus.venueCode);
        ReactGA.initialize("G-7V3X25H5M2");
        ReactGA.set({venue_code: sessionStatus.venueCode});
        
        if (sessionId === null) {
            navigate(`/invalidlink?session`);
            return;
        }

        switch (sessionStatus?.status) {
            case "Started":
                ReactGA.event("RapidEntryStarted", {venue_code:sessionStatus.venueCode});
                dispatch(updateSessionId(sessionId));
                dispatch(updateTableNumber(tableNumber));
                navigate(`/nickname?s=${sessionId}&t=${tableNumber}&v=${sessionStatus.venueCode}`);
                break;
            case "Finished":
                navigate(`/sessioncompleted`);
                break;
            case "Pending":
                navigate(`/invalidlink?pending`);
                break;
            case "Not Found":
            case null:
            case undefined:
                navigate(`/invalidlink?undefined`);
                break;
        }
    }, [sessionStatus, navigate, sessionId, tableNumber, dispatch]);

    // switch (sessionStatus) {
    //     case "Wait":
    //         return (<Wait />);
    // }
    return (<></>);
}