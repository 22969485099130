import './progressBar.css';

export const ProgressBar: React.FC<{progress: number}> = ({progress}) => {
    return (<ul className="progressContainer clearfix">
    <li className={`progressButton ${progress >= 1 ? "button-complete" : ""} ${progress === 1 ? "button-active" : ""}`}>
        <div className="progressButton-border">
            <span className="progressButton-dot"></span>
        </div>
        <div className="progressLabel">Name</div>
    </li>
    <li className={`progressButton ${progress >= 2 ? "button-complete" : ""} ${progress === 2 ? "button-active" : ""}`}>
        <div className="progressButton-border">
            <span className="progressButton-dot"></span>
        </div>
        <div className="progressLabel">Photo</div>
    </li>
    <li className={`progressButton ${progress >= 3 ? "button-complete" : ""} ${progress === 3 ? "button-active" : ""}`}>
        <div className="progressButton-border">
            <span className="progressButton-dot"></span>
        </div>
        <div className="progressLabel">Story</div>
    </li>
</ul>);
}