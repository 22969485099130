import { useSelector } from "react-redux"
import { getPhoto, getEmail, getName, getSessionId, getTableNumber, clearAction } from "../redux/slices";
import { useEffect, useState } from "react";

import { useAppDispatch } from "../redux/hooks";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Loading } from './Loading';

import './playerEntryComplete.css';

export const PlayerEntryComplete: React.FC = () => {
    const nav = useNavigate();
    const name = useSelector(getName);
    const email = useSelector(getEmail);
    const photo = useSelector(getPhoto);
    const dispatch = useAppDispatch();

    // BEGIN copied link checking
    const [loading, setLoading] = useState<boolean>(true);
    const [searchParams] = useSearchParams();

    // if the redux store is missing a session ID (or the session ID does not match the URL parameter), 
    // then redirect to the start page. Suspect that this is a shared link and this "new" user needs to start at the beginning of the journey
    let sessionIdParam = searchParams.get('s');
    let tableNumberParam = searchParams.get('t');
    let venueCode = searchParams.get('v');
    const sessionIdRedux = useSelector(getSessionId);
    const tableNumberRedux = useSelector(getTableNumber);

    useEffect(() => {
        if (sessionIdRedux === null || tableNumberRedux === null) {
            nav(`/?s=${sessionIdParam}&t=${tableNumberParam}&v=${venueCode}`);
        }

        if (sessionIdParam !== sessionIdRedux || tableNumberParam !== tableNumberRedux) {
            nav(`/?s=${sessionIdParam}&t=${tableNumberParam}&v=${venueCode}`);
        }
        else {
            setLoading(false);
        }
    }, [sessionIdParam, tableNumberParam, venueCode, sessionIdRedux, tableNumberRedux, nav]);

    // start the process again
    const addfriend = () => {
        dispatch(clearAction());
        nav(`/nickname?s=${sessionIdParam}&t=${tableNumberParam}&v=${venueCode}`);
    };

    if (loading) return (<Loading />);

    return (
        <div className="pageContainer">
            <div className="logoContainer">
                <img src={'./img/es-logo.svg'} width="90px" alt="Electric Shuffle" />
            </div>
            <div className="completeText">Great! We will send all your<br />Photos and Action Replays to:<br /><span className="completeEmail">{email}</span></div>
            <div className="completeText">Now, it's time to play!</div>
            <div className="playerPhotoContainer complete">
                <img className="playerPhotoMask" src={photo} alt="" />
                <div className="playerPhotoBorder"><img src="./img/photo_border.png" alt="" /></div>
            </div>
            <div className="playerNameBannerCenter">{name}</div>

            <footer className="btn-container">
                <div className="morePlayersText">More players in your group?</div>
                <button className="button" onClick={addfriend}>ADD A FRIEND</button><br />
            </footer>
        </div>);
}
