export const CameraError: React.FC = () => {
    return (
        <div className="pageContainer">
            <div className="logoContainer">
                <img src={'./img/es-logo.svg'} width="90px" alt="Electric Shuffle" />
            </div>
            <div className="errorContainer">
                <div className="errorTitleText">Can't access camera</div>
                <div className="errorText">Please use the touchscreen or talk to a host in venue</div>
            </div>
        </div>
    );
}